import {
	HomeIcon,
	InboxIcon,
	DocumentIcon,
	AcademicCapIcon,
	ArrowDownCircleIcon,
	LifebuoyIcon,
	VideoCameraIcon,
	FaceSmileIcon,
	DocumentTextIcon,
} from "@heroicons/react/24/outline";
import NavItem from "./NavItem";

const NavList = () => {
	const navigation = [
		{
			key: "1",
			name: "Dashboard",
			href: "/",
			icon: HomeIcon,
			classNames: "pb-9",
		},

		{
			key: "2",
			name: "Personeelsreglement",
			href: "/personeelsreglementen/",
			icon: InboxIcon,
		},
		{
			key: "3",
			name: "Contracten",
			href: "/contracten/",
			icon: DocumentIcon,
			plusIcon: true,
		},
		{
			key: "4",
			name: "ZZP",
			href: "/ovos/",
			icon: DocumentTextIcon,
			plusIcon: true,
			classNames: "pb-9",
		},
		{
			key: "5",
			name: "Teamtevredenheid",
			href: "/teamtevredenheid/",
			icon: FaceSmileIcon,
			classNames: "pb-9",
		},
		{
			key: "6",
			name: "Downloads",
			href: "/downloads",
			icon: ArrowDownCircleIcon,
		},
		{
			key: "7",
			name: "Online lessen",
			href: "https://mijn.dentcourses.com/training/arbeidsrecht-in-de-mondzorg/",
			external: true,
			icon: AcademicCapIcon,
		},
		{
			key: "8",
			name: "Webinar",
			href: "/webinar",
			icon: VideoCameraIcon,
			classNames: "pb-9",
		},

		{
			key: "9",
			name: "Hulp nodig?",
			href: "https://help.dentiva.nl/nl/collections/6696657-denthr",
			icon: LifebuoyIcon,
			external: true,
		},
	];

	return (
		<>
			{navigation.map((item) => (
				<>
					<NavItem item={item} key={item.key} />
				</>
			))}
		</>
	);
};

export default NavList;
